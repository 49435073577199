<template>
  <v-container>
    <page-header text="Locations" />
    <div class="text-center">
      <v-btn class="mb-5" color="primary" :to="{ name: 'AdminLocationsNew' }"
        >+ New Location</v-btn
      >
    </div>
    <v-simple-table>
      <tbody>
        <tr v-for="item in data" :key="item.id">
          <td>
            <v-icon :color="item.enabled ? 'accent' : 'secondary'">
              mdi-map-marker
            </v-icon>
          </td>
          <td>
            <h4>{{ item.name }}</h4>
            <div class="d-none d-md-table-cell">
              {{ item.description }}
            </div>
          </td>
          <td class="d-none d-md-table-cell">{{ item.nearest_place }}</td>
          <td>
            <v-btn
              small
              color="light"
              :to="{ name: 'AdminLocationsEdit', params: { id: item.id } }"
              >Edit</v-btn
            >
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-container>
</template>
<script>
import PageHeader from "@/components/public/PageHeader.vue";
export default {
  name: "AdminLocations",

  components: {
    PageHeader,
  },

  data() {
    return {
      data: [],
      error: "",
    };
  },
  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      var url = "";
      url += process.env.VUE_APP_BACKEND_API_URL;
      url += "/admin/locations";

      fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.data = data;
        }
      });
    },
  },
};
</script>
