<template>
  <v-form ref="form">
    <v-alert type="success" dark v-if="message" required>{{
      this.message
    }}</v-alert>

    <v-text-field
      label="Enter your email"
      v-model="email"
      :rules="emailRules"
    ></v-text-field>
    <v-btn color="primary" @click="submit" class="float-right"
      >Send reset link</v-btn
    >
  </v-form>
</template>

<script>
export default {
  name: "forgot-password-form",

  data: () => ({
    email: "",
    message: "",
    emailRules: [(v) => !!v || "Email is required"],
  }),

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        fetch(`${process.env.VUE_APP_BACKEND_API_URL}/password_resets`, {
          method: "POST",
          body: this.formData(),
        }).then(async (response) => {
          // The response will always be an OK so as to not give away
          // any details about the user.
          if (response.ok) {
            this.message =
              "Password instructions sent, please check your inbox";
          }
        });
      }
    },

    formData() {
      const form = new FormData();
      form.append("email", this.email);
      return form;
    },
  },
};
</script>
