<template>
  <div class="d-flex align-center mt-5">
    <div class="d-flex justify-end mb-3" style="width: 100%">
      <navbar-dropdown label="About" :items="about" />
      <navbar-dropdown label="Events" :items="events" />
      <v-btn text color="primary" class="d-none d-md-inline-flex" to="/blog"
        >Blog</v-btn
      >
    </div>
    <navbar-logo class="mx-3" />
    <div
      class="d-flex justify-end justify-md-start mb-3 me-3"
      style="width: 100%"
    >
      <v-btn text class="d-none d-md-inline-flex" color="primary" to="/shop"
        >Shop</v-btn
      >
      <v-btn text class="d-none d-md-inline-flex" color="primary" to="/contact"
        >Contact</v-btn
      >
      <v-btn
        v-if="!this.$store.state.user"
        color="accent"
        class=""
        rounded
        to="/sign-in"
        >Sign in
      </v-btn>
      <navbar-account v-if="this.$store.state.user" />
    </div>
  </div>
</template>

<script>
import NavbarDropdown from "@/components/public/navbar/NavbarDropdown";
import NavbarLogo from "@/components/public/navbar/NavbarLogo";
import NavbarAccount from "@/components/public/navbar/NavbarAccount";

export default {
  name: "navbar",

  components: {
    NavbarDropdown,
    NavbarLogo,
    NavbarAccount,
  },

  data: () => ({
    about: [
      { label: "AquaPaddle", url: "/about" },
      { label: "AquaPaddle - BlueSpace", url: "/bluespace" },
      { label: "Meet the team", url: "/meet-the-team" },
      { label: "Supporters", url: "/supporters" },
      { label: "Volunteer", url: "/volunteer" },
    ],
    events: [
      { label: "Upcoming", url: "/paddles" },
      { label: "Finished", url: "/finished-paddles" },
    ],
  }),
};
</script>
