<template>
  <v-container>
    <page-header text="Users" />
    <v-data-table :headers="headers" :items="data" :search="search">
      <template v-slot:top>
        <v-text-field v-model="search" label="Search"> </v-text-field>
      </template>
      <template v-slot:item.id="{ item }">
        <router-link :to="{ name: 'AdminUserEdit', params: { id: item.id } }">{{
          item.id
        }}</router-link>
      </template>

      <template v-slot:item.organiser="{ item }">
        {{ item.organiser ? "Yes" : "" }}
      </template>

      <template v-slot:item.locked="{ item }">
        {{ item.locked ? "Yes" : "" }}
      </template>
    </v-data-table>

    <v-btn color="primary" @click="exportCsv()">Download CSV</v-btn>
  </v-container>
</template>
<script>
import PageHeader from "@/components/public/PageHeader.vue";
export default {
  name: "AdminUsers",

  components: {
    PageHeader,
  },

  data() {
    return {
      data: [],
      error: "",
      search: "",
      headers: [
        { text: "ID", value: "id", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "First Name", value: "first_name", sortable: true },
        { text: "Last Name", value: "last_name", sortable: true },
        { text: "Organiser", value: "organiser", sortable: true },
        { text: "Locked", value: "locked", sortable: true },
      ],
    };
  },
  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      var url = "";
      url += process.env.VUE_APP_BACKEND_API_URL;
      url += "/admin/users";

      fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.data = data;
        }
      });
    },

    exportCsv() {
      var url = "";
      url += process.env.VUE_APP_BACKEND_API_URL;
      url += "/admin/users/export_csv";

      fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => res.blob())
        .then((blob) => {
          var file = window.URL.createObjectURL(blob);
          window.location.assign(file);
        });
    },
  },
};
</script>
