<template>
  <v-card class="mb-5 mx-auto" style="max-width: 500px">
    <v-card-title class="body-1 font-weight-bold">
      {{ prettyDate(race.scheduled_start_time) }}
      <span class="font-weight-light ml-2 body-2 mr-auto">
        {{ extractTime(race.scheduled_start_time) }}
      </span>
      <v-btn
        v-if="!race.finished"
        class="justify-self-end"
        color="accent"
        fab
        small
        :to="`/paddles/${race.id}/timer`"
        ><v-icon>mdi-timer</v-icon>
      </v-btn>

      <v-btn
        v-if="!race.finished"
        class="ms-2"
        color="primary"
        fab
        small
        :to="`/organiser/${race.id}/edit`"
        ><v-icon>mdi-pencil</v-icon>
      </v-btn>

      <div class="me-2">
        <IceDetails v-bind:race_id="race.id" v-if="race.finished" />
      </div>

      <v-menu v-if="race.finished">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="accent" fab small v-bind="attrs" v-on="on">
            <v-icon>mdi-car-emergency</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            :to="{ name: 'NewIncidentReport', params: { id: race.id } }"
          >
            <v-list-item-title>+ New incident report</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="(item, index) in race.incident_reports"
            :to="{ name: 'EditIncidentReport', params: { id: item.id } }"
            :key="index"
          >
            <v-list-item-title>Edit incident #{{ item.id }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
        v-if="race.finished"
        class="ms-2"
        color="primary"
        fab
        small
        :to="`/paddles/${race.id}`"
        ><v-icon>mdi-information-variant</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-subtitle>
      {{ race.name }}
    </v-card-subtitle>

    <v-card-text style="">
      <span class="mr-2">
        <v-icon small color="primary">mdi-map</v-icon>
        {{ race.distance }}k
      </span>

      <span class="mr-2">
        <v-icon small color="primary">mdi-map-marker</v-icon>
        Location
      </span>
    </v-card-text>
  </v-card>
</template>

<script>
import IceDetails from "@/components/organiser/paddle-timer/IceDetails.vue";

export default {
  name: "eventCard",
  props: ["race"],
  components: {
    IceDetails,
  },
};
</script>
