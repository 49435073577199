<template>
  <div>
    <PaddleTimerButton
      :position="index + 1"
      :paddler="paddler"
      :time="true"
      @click.native="dialog = true"
    />

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h6 grey lighten-2">
          Editing result
        </v-card-title>

        <v-card-text>
          <h6 class="text-h6 mt-3">Remove</h6>
          <p>
            Recorded this time by mistake? Click below to remove it forever.
          </p>
          <v-btn color="primary" @click="remove(paddler)">Remove</v-btn>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text v-if="paddler.tag != '??????'">
          <h6 class="text-h6 mt-3">Set to "unknown paddler"</h6>
          <p>Keep this time recorded but set the name to "unknown paddler".</p>
          <v-btn color="primary" @click="replaceWithUnknown(paddler)"
            >Make unknown</v-btn
          >
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text v-if="dnfers().length > 0">
          <h6 class="text-h6 mt-3">Replace</h6>
          <p>Reassign this result to another paddler who is yet to finish.</p>

          <div class="paddle-timer-content mx-0">
            <div class="" v-for="(replacement, index) in dnfers()" :key="index">
              <PaddleTimerButton
                :paddler="replacement"
                icon="swap-horizontal"
                color="primary"
                @click.native="replace(paddler, replacement)"
              />
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="dialog = false"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store/index";
import PaddleTimerButton from "@/components/organiser/paddle-timer/PaddleTimerButton.vue";

export default {
  name: "ResultOptions",
  props: ["race", "paddler", "finishers", "index"],
  components: {
    PaddleTimerButton,
  },
  data() {
    return {
      dialog: false,
    };
  },

  methods: {
    dnfers() {
      return this.race["paddlers"].filter((paddler) => paddler.time == 0);
    },

    remove(paddler) {
      if (paddler.tag == "??????") {
        this.removeUnknown(paddler);
      } else {
        this.removePaddler(paddler);
      }
      this.dialog = false;
      store.commit("flash", {
        type: "success",
        text: `${paddler.first_name}'s time was removed`,
      });
    },

    removePaddler(paddler) {
      paddler.time = 0;
    },

    removeUnknown(paddler) {
      // Unknown paddlers should be removed from the paddlers array so
      // they don't appear in the checkin or timer screens.
      const index = this.race["paddlers"].findIndex(
        (obj) => obj.id === paddler.id
      );
      this.race["paddlers"].splice(index, 1);
    },

    replace(old, replacement) {
      replacement.time = old.time;
      this.remove(old);
      store.commit("flash", {
        type: "success",
        text: `${replacement.first_name} replaces ${old.first_name}`,
      });
    },

    replaceWithUnknown(paddler) {
      // Duplicated code from the timer component. Far from ideal and
      // smells pretty bad so should be refactored at some point.
      this.race["paddlers"].push({
        id: this.uniqueId(),
        tag: "??????",
        first_name: "unknown paddler",
        time: paddler.time,
      });
      this.removePaddler(paddler);
      this.dialog = false;
      store.commit("flash", {
        type: "success",
        text: "Replaced with 'unknown paddler'",
      });
    },

    uniqueId() {
      // Another duplicated method from the timer component which should
      // be dry'd up or refactored out.
      return "_" + Math.random().toString(36).substr(2, 9);
    },
  },
};
</script>
