<template>
  <v-alert
    v-if="content"
    color="primary"
    class="text-center homepage-flash-message"
    dark
  >
    <div v-html="content.content.rendered"></div>
  </v-alert>
</template>
<script>
export default {
  name: "homepage-flash-message",

  data: () => ({
    content: "",
  }),

  methods: {
    getData() {
      var url = "";
      url += process.env.VUE_APP_CMS_API_URL;
      url += "/pages/95";

      fetch(url, {
        method: "GET",
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.content = data;
        }
      });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>

<style>
.homepage-flash-message a {
  color: white !important;
}

.homepage-flash-message p {
  margin: 0 !important;
}
</style>
