<template>
  <v-container>
    <page-header text="Blog" />
    <blog-index />
  </v-container>
</template>

<script>
import PageHeader from "@/components/public/PageHeader.vue";
import BlogIndex from "@/components/public/blog/Index.vue";

export default {
  name: "blogs",

  components: {
    PageHeader,
    BlogIndex,
  },
};
</script>
