<template>
  <div>
    <v-btn
      v-if="$vuetify.breakpoint.smAndDown"
      @click="drawer = !drawer"
      fab
      absolute
      elevation="0"
      color="white"
      class="mt-5"
      ><v-icon color="accent">mdi-menu</v-icon>
    </v-btn>

    <v-navigation-drawer fixed class="primary" v-model="drawer" dark>
      <div class="drawer-bar">
        <v-icon v-if="!drawer" @click="drawer = !drawer" color="accent">
          mdi-menu
        </v-icon>
        <v-icon v-if="drawer" @click="drawer = !drawer" color="accent">
          mdi-close
        </v-icon>
      </div>

      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.label"
          :to="item.url"
          @click="drawer = false"
        >
          <v-list-item-title>
            {{ item.label }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "drawer",

  data: () => ({
    drawer: false,
    items: [
      { label: "Home", url: "/" },
      { label: "About", url: "/about" },
      { label: "AquaPaddle - Bluespace", url: "/bluespace" },
      { label: "Meet the team", url: "/meet-the-team" },
      { label: "Supporters", url: "/supporters" },
      { label: "Volunteer", url: "/volunteer" },
      { label: "Blog", url: "/blog" },
      { label: "Shop", url: "/shop" },
      { label: "Upcoming paddles", url: "/paddles" },
      { label: "Finished paddles", url: "/finished-paddles" },
      { label: "Contact", url: "/contact" },
    ],
  }),

  methods: {
    signOut() {
      this.drawer = false;
      this.$store.commit("signOut");
    },
  },
};
</script>

<style>
.drawer-bar {
  position: absolute;
  height: 100%;
  width: 60px;
  top: 0;
  left: 0;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.v-navigation-drawer {
  padding-left: 60px;
  width: 320px !important;
}
</style>
