<template>
  <div class="d-flex justify-center mt-8">
    <div
      v-if="!race_registrations.find((item) => item.race.id == race_id)"
      class="text-center"
    >
      <v-checkbox
        v-model="termsAccepted"
        label="I accept the terms and conditions and agree the disclaimer"
      >
      </v-checkbox>

      <router-link to="/aquapaddle-event-terms-conditions">
        Click here to read event terms, conditions and disclaimer
      </router-link>

      <v-btn color="accent" class="mt-6" block large rounded @click="enter_race"
        >Enter this event
      </v-btn>
    </div>

    <div
      class="text-center"
      v-if="race_registrations.find((item) => item.race.id == race_id)"
    >
      <v-btn color="green" large rounded dark>You are in this event</v-btn>
      <p class="mt-3">
        If you feel that AquaPaddle is a benefit to you, please consider making
        us a donation, for example £10? It all goes to making AquaPaddle the
        best it can be!
      </p>

      <v-btn
        href="https://donate.kindlink.com/AquaPaddle/4769/0"
        target="_blank"
        color="accent"
        large
        rounded
        dark
        >Donate Now</v-btn
      >
    </div>
  </div>
</template>

<script>
import store from "@/store/index";

export default {
  name: "enter-race-button",

  props: ["race_id"],

  data: () => ({
    race_registrations: [],
    termsAccepted: false,
  }),

  methods: {
    enter_race() {
      if (!this.termsAccepted) {
        var message = "Please accept the terms & conditions";
        store.commit("flash", { type: "error", text: message });
        return;
      }
      var url = "";
      url += process.env.VUE_APP_BACKEND_API_URL;
      url += "/user/race-registrations";

      const form = new FormData();
      form.append("race_id", this.race_id);

      fetch(url, {
        method: "POST",
        body: form,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.getData();
        }
      });
    },

    getData() {
      var url = "";
      url += process.env.VUE_APP_BACKEND_API_URL;
      url += "/user/race-registrations";

      fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.race_registrations = data;
        }
      });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
