<template>
  <v-form>
    <v-alert outlined color="red" dark v-if="error">
      {{ this.error }}
    </v-alert>

    <v-text-field label="Email" v-model="email"></v-text-field>

    <v-text-field
      label="Password"
      v-model="password"
      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
      :type="show ? 'text' : 'password'"
      @click:append="show = !show"
    ></v-text-field>

    <div class="d-sm-flex justify-space-between">
      <p>
        <router-link to="/sign-up">Click here to sign up</router-link>
        &nbsp;|&nbsp;
        <router-link to="/forgot-password">Forgot password</router-link>
      </p>
      <v-btn color="primary" @click="submit" class="float-right">Sign in</v-btn>
    </div>
  </v-form>
</template>

<script>
import store from "@/store/index";

export default {
  name: "sign-in-form",

  data: () => ({
    email: "",
    password: "",
    error: "",
    show: false,
  }),

  methods: {
    submit() {
      fetch(`${process.env.VUE_APP_BACKEND_API_URL}/sign-in`, {
        method: "POST",
        body: this.formData(),
      })
        .then(async (response) => {
          const data = await response.json();

          if (!response.ok) {
            this.error = data["error"];
          }

          if (response.ok) {
            var token = data["token"];
            store.commit("signIn", token);
            this.$router.push({ name: "Home" });
          }
        })

        .catch((error) => {
          console.log(error);
          this.error = "We couldn't sign you in because of a problem";
        });
    },

    formData() {
      const form = new FormData();
      form.append("email", this.email);
      form.append("password", this.password);
      return form;
    },
  },
};
</script>
