<template>
  <div>
    <v-row>
      <v-col class="text-center">
        <h4 class="text-h3 accent--text font-weight-bold mb-3">
          {{ profile.tag.substr(0, 3) }}
          <br />
          {{ profile.tag.substr(3, 5) }}
        </h4>
        <v-chip label small class="mb-1">
          {{ profile.race_results.length }}
          AquaPaddles completed
        </v-chip>
        <br />
        <v-chip label small>
          Member since
          {{ this.prettyDate(profile.created_at) }}
        </v-chip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "profile-body",

  props: ["profile"],
};
</script>
