<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="error" class="text-center" v-bind="attrs" v-on="on"
        >Restart</v-btn
      >
    </template>

    <v-card>
      <v-card-title class="text-h6 grey lighten-2">
        Restart Paddle
      </v-card-title>

      <v-card-text>
        <p class="mt-3">
          Do you really want to restart this paddle? Your list of checkins will
          be saved but any times you recorded will be lost.
        </p>
        <v-btn color="primary" @click="restart()">Restart</v-btn>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "@/store/index";

export default {
  name: "RestartDialog",
  props: ["race"],
  data() {
    return {
      dialog: false,
    };
  },

  methods: {
    restart() {
      this.race["start_time"] = 0;
      this.removeUnknownPaddlers();
      this.resetTimes();
      store.commit("flash", { type: "success", text: "Paddle restarted" });
    },

    resetTimes() {
      this.race["paddlers"].forEach((paddler) => {
        paddler.time = 0;
      });
    },

    removeUnknownPaddlers() {
      const list = this.race["paddlers"].filter((item) => item.tag == "??????");
      list.forEach((paddler) => {
        const index = this.race["paddlers"].findIndex(
          (obj) => obj.id === paddler.id
        );
        this.race["paddlers"].splice(index, 1);
      });
    },
  },
};
</script>
