import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: "#50A8AB",
        secondary: "#B3DEEA",
        accent: "#E93F66",
      },
    },
  },
};

export default new Vuetify(opts);
