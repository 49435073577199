<template>
  <v-container>
    <page-header text="Upcoming Paddles" />
    <upcoming />
  </v-container>
</template>

<script>
import PageHeader from "@/components/public/PageHeader.vue";
import Upcoming from "@/components/public/races/Upcoming.vue";

export default {
  name: "races",

  components: {
    PageHeader,
    Upcoming,
  },
};
</script>
