<template>
  <div>
    <TimerHeader
      v-if="race['start_time'] <= 0"
      icon="timer-sand"
      title="Waiting for race to start"
      subtitle="The race has not yet started"
    />

    <TimerHeader
      v-if="race['start_time'] > 0 && finishers().length <= 0"
      icon="timer-sand"
      title="Waiting for paddlers to finish"
      subtitle="Nobody has finished the paddle yet"
    />

    <TimerHeader
      v-if="finishers().length > 0"
      icon="flag-checkered"
      title="Results"
      :subtitle="`${finishers().length} paddlers have finished the race`"
    />

    <v-container>
      <v-row>
        <v-col
          :cols="cols"
          v-for="(paddler, index) in finishers()"
          :key="index"
        >
          <ResultOptions
            v-bind:paddler="paddler"
            v-bind:index="index"
            v-bind:finishers="finishers()"
            v-bind:race="race"
          />
        </v-col>
      </v-row>
    </v-container>

    <div class="text-center mt-5" v-if="finishers().length > 0">
      <PublishResultsDialog v-bind:race="race" />
    </div>
  </div>
</template>

<script>
import TimerHeader from "@/components/organiser/paddle-timer/TimerHeader.vue";
import ResultOptions from "@/components/organiser/paddle-timer/ResultOptions.vue";
import PublishResultsDialog from "@/components/organiser/paddle-timer/PublishResultsDialog.vue";

export default {
  name: "Results",
  props: ["race", "cols"],
  components: {
    TimerHeader,
    ResultOptions,
    PublishResultsDialog,
  },
  data() {
    return {};
  },

  methods: {
    finishers() {
      return this.race["paddlers"]
        .filter((paddler) => paddler.time > 0)
        .sort((a, b) => a.time - b.time);
    },

    finish(paddler) {
      if (paddler.tag == "??????") {
        console.log("removing unknown paddler");
      } else {
        paddler.time = 0;
      }
    },
  },
};
</script>
