<template>
  <v-tabs centered class="mb-8">
    <v-tab exact-path to="/organiser">Upcoming</v-tab>
    <v-tab exact-path to="/organiser/create">New Event</v-tab>
    <v-tab exact-path to="/organiser/finished">Finished</v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: "Menu",
};
</script>
