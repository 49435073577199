<template>
  <v-container>
    <page-header text="My Registrations" />

    <p v-if="registrations.length == 0" class="text-center">
      You have not registered for any upcoming paddles.
    </p>

    <v-card
      v-for="item in registrations"
      :key="item.id"
      class="mb-5 text-center"
      flat
    >
      <v-card-title class="justify-center">
        {{ item.race.name }}
      </v-card-title>

      <v-card-subtitle>
        {{ prettyDate(item.race.scheduled_start_time) }}
        |
        {{ extractTime(item.race.scheduled_start_time) }}
        |
        {{ item.race.location }}
      </v-card-subtitle>

      <v-card-text>
        <v-btn
          color="primary"
          class="ma-1"
          :to="{ name: 'Race', params: { id: item.race.id } }"
          small
          rounded
          >Paddle details
        </v-btn>

        <v-btn
          color="accent"
          class="ma-1"
          small
          rounded
          @click="cancelRegistration(item.id)"
          >Cancel registration
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import store from "@/store/index";
import PageHeader from "@/components/public/PageHeader.vue";

export default {
  name: "user-race-registrations",

  components: {
    PageHeader,
  },

  data: () => ({
    error: "",
    registrations: [],
  }),

  methods: {
    cancelRegistration(id) {
      const form = new FormData();
      form.append("id", id);

      fetch(`${process.env.VUE_APP_BACKEND_API_URL}/user/race-registrations`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        method: "DELETE",
        body: form,
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          store.commit("flash", { type: "success", text: "Cancelled" });
          this.getData();
        }
      });
    },

    getData() {
      fetch(`${process.env.VUE_APP_BACKEND_API_URL}/user/race-registrations`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.registrations = data;
        }
      });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
