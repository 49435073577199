<template>
  <v-form>
    <v-text-field
      v-model="user.email"
      label="Email address"
      :error-messages="error['email']"
    ></v-text-field>

    <v-text-field
      v-model="user.first_name"
      label="First name"
      :error-messages="error['first_name']"
    ></v-text-field>

    <v-text-field
      v-model="user.last_name"
      label="Last name"
      :error-messages="error['last_name']"
    ></v-text-field>

    <v-text-field
      v-model="user.emergency_contact_name"
      label="Emergency contact name"
      :error-messages="error['emergency_contact_name']"
    ></v-text-field>

    <v-text-field
      v-model="user.emergency_contact_number"
      label="Emergency contact number"
      :error-messages="error['emergency_contact_number']"
    ></v-text-field>

    <v-text-field
      v-model="user.password"
      label="Password"
      :error-messages="error['password']"
      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
      :type="show ? 'text' : 'password'"
      @click:append="show = !show"
    ></v-text-field>

    <v-btn color="primary" @click="submit">Save</v-btn>
  </v-form>
</template>

<script>
import store from "@/store/index";

export default {
  name: "edit-user-form",

  data: () => ({
    error: "",
    user: {},
    show: false,
  }),

  methods: {
    submit() {
      const form = new FormData();
      form.append("[user]email", this.user.email);
      form.append("[user]first_name", this.user.first_name);
      form.append("[user]last_name", this.user.last_name);
      form.append(
        "[user]emergency_contact_name",
        this.user.emergency_contact_name
      );
      form.append(
        "[user]emergency_contact_number",
        this.user.emergency_contact_number
      );

      if (this.user.password) {
        form.append("[user]password", this.user.password);
      }

      fetch(`${process.env.VUE_APP_BACKEND_API_URL}/user/account`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        method: "PATCH",
        body: form,
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          var token = data["token"];
          store.commit("flash", { type: "success", text: "Saved" });
          store.commit("signIn", token);
        }
      });
    },

    getUser() {
      fetch(`${process.env.VUE_APP_BACKEND_API_URL}/user/account`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.user = data;
        }
      });
    },
  },

  mounted() {
    this.getUser();
  },
};
</script>
