import Vue from "vue";
import App from "./App.vue";
//import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify";
import "@mdi/font/css/materialdesignicons.css";

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    prettyDate: function (date) {
      const d = new Date(date);
      const day = d.getDate();
      const month = d.getMonth();
      const year = d.getFullYear();
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return [day, monthNames[month], year].join(" ");
    },

    extractTime: function (date) {
      const d = new Date(date);
      return d.toISOString().slice(11, 16);
    },

    prettyTime: function (ms) {
      const seconds = Math.floor((ms / 1000) % 60);
      const minutes = Math.floor((ms / 1000 / 60) % 60);
      const hours = Math.floor((ms / 1000 / 3600) % 24);
      const humanize = [
        hours.toLocaleString("en-us", { minimumIntegerDigits: 2 }),
        minutes.toLocaleString("en-us", { minimumIntegerDigits: 2 }),
        seconds.toLocaleString("en-us", { minimumIntegerDigits: 2 }),
      ];
      return humanize.join(":");
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
