<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="success" class="text-center" v-bind="attrs" v-on="on"
        >Finish Paddle</v-btn
      >
    </template>

    <v-card>
      <v-card-title class="text-h6 grey lighten-2">
        Finish Paddle
      </v-card-title>

      <v-card-text>
        <v-form>
          <v-radio-group
            label="Any incidents?"
            v-model="incident_report.incident"
          >
            <v-radio label="No" :value="false"></v-radio>
            <v-radio label="Yes" :value="true"></v-radio>
          </v-radio-group>

          <v-radio-group label="Any injurys?" v-model="incident_report.injury">
            <v-radio label="No" :value="false"></v-radio>
            <v-radio label="Yes" :value="true"></v-radio>
          </v-radio-group>

          <v-radio-group
            label="Any help required?"
            v-model="incident_report.assistance"
          >
            <v-radio label="No" value="No"></v-radio>
            <v-radio label="First Aid" value="First Aid"></v-radio>
            <v-radio
              label="Medical Professional"
              value="Medical Professional"
            ></v-radio>
            <v-radio
              label="Emergency Services"
              value="Emergeny Services"
            ></v-radio>
          </v-radio-group>

          <v-radio-group
            label="Follow up required?"
            v-model="incident_report.followup"
          >
            <v-radio label="No" :value="false"></v-radio>
            <v-radio label="Yes" :value="true"></v-radio>
          </v-radio-group>

          <v-radio-group
            label="Incident type"
            v-model="incident_report.category"
          >
            <v-radio label="N/A" value="N/A"></v-radio>
            <v-radio label="Fall in" value="Fall in"></v-radio>
            <v-radio label="Injury" value="Injury"></v-radio>
            <v-radio
              label="Medical Condition"
              value="Medical Condition"
            ></v-radio>
            <v-radio
              label="Emergency Service/Third Party Call Out"
              value="Emergency Service/Third Party Call Out"
            ></v-radio>
            <v-radio
              label="Other Waterway User"
              value="Other Waterway User"
            ></v-radio>
            <v-radio
              label="Access/Navigation"
              value="Access/Navigation"
            ></v-radio>
            <v-radio
              label="Angling Dispute Environmental"
              value="Angling Dispute Environmental"
            ></v-radio>
            <v-radio label="Pollution" value="Pollution"></v-radio>
            <v-radio label="Wildlife" value="Wildlife"></v-radio>
            <v-radio
              label="Equipment Failure"
              value="Equipment Failure"
            ></v-radio>
            <v-radio
              label="Report Under RIDDOR"
              value="Report Under RIDDOR"
            ></v-radio>
            <v-radio label="Near miss" value="Near miss"></v-radio>
            <v-radio label="COVID-19" value="COVID-19"></v-radio>
            <v-radio label="Other" value="Other"></v-radio>
          </v-radio-group>
        </v-form>
      </v-card-text>

      <v-card-text>
        <p class="mt-3">
          Are you sure you want to finish this paddle? The results will be
          posted on the AquaPaddle website. Any 'unknown paddlers' will be
          ignored.
        </p>
        <v-btn color="primary" @click="publish()">Finish Paddle</v-btn>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "@/store/index";

export default {
  name: "PublishResultsDialog",
  props: ["race"],
  data() {
    return {
      dialog: false,
      incident_report: {
        id: "",
        incident: false,
        injury: false,
        assistance: "No",
        followup: false,
        category: "",
        details: "",
      },
    };
  },

  methods: {
    results() {
      return this.race["paddlers"]
        .filter((paddler) => paddler.tag != "??????" && paddler.time > 0)
        .sort((a, b) => a.time - b.time);
    },

    payload() {
      var data = {
        race: {
          start_time: this.race["start_time"],
          results: this.results(),
          incident_report: this.incident_report,
        },
      };
      return JSON.stringify(data);
    },

    publish() {
      fetch(
        `${process.env.VUE_APP_BACKEND_API_URL}/organiser/races/${this.race["id"]}/finish`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: this.payload(),
        }
      ).then(async (response) => {
        if (!response.ok) {
          store.commit("flash", {
            type: "error",
            text: "Unable to publish results",
          });
        }

        if (response.ok) {
          store.commit("flash", { type: "success", text: "Paddle completed" });
          this.race["finished"] = true;
          this.dialog = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.v-input >>> .v-label:first-child {
  font-size: 20px !important;
}
</style>
