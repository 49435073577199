<template>
  <v-container>
    <page-header text="My Profile" />
    <edit-form />
  </v-container>
</template>

<script>
import PageHeader from "@/components/public/PageHeader.vue";
import EditForm from "@/components/user/profile/EditForm.vue";

export default {
  name: "profile-edit",

  components: {
    PageHeader,
    EditForm,
  },
};
</script>
