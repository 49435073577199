<template>
  <div>
    <v-card color="primary" class="text-center mb-5" dark>
      <v-card-text>
        <h4
          class="text-h6 text-center location-description"
          v-html="location.description"
        />
      </v-card-text>
    </v-card>

    <div v-html="location.embedded_map"></div>

    <v-btn
      :href="`https://what3words.com/${location.what3words}`"
      class="my-5"
      color="accent"
      target="_blank"
      >Find on What 3 Words
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "location-details",

  props: ["location"],
};
</script>

<style>
iframe {
  max-width: 100%;
}

.location-description a {
  color: #e93f66 !important;
}
</style>
