<template>
  <div class="text-center mb-5">
    <v-icon color="accent" class="mb-3" large>mdi-{{ icon }}</v-icon>
    <h2 class="h2">{{ title }}</h2>
    <p>{{ subtitle }}</p>
  </div>
</template>

<script>
export default {
  name: "TimerHeader",
  props: ["title", "subtitle", "icon"],
};
</script>
