<template>
  <v-menu>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" icon v-bind="attrs" v-on="on">
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(item, index) in items" :to="item.url" :key="index">
        <v-list-item-title>{{ item.label }}</v-list-item-title>
      </v-list-item>

      <v-list-item :to="profileUrl()">
        <v-list-item-title>My Profile</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="this.$store.state.user.organiser" to="/organiser">
        <v-list-item-title>Organiser</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="this.$store.state.user.admin"
        to="/admin/incident-reports"
      >
        <v-list-item-title>Incident Reports</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="this.$store.state.user.admin" to="/admin/locations">
        <v-list-item-title>Locations</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="this.$store.state.user.admin" to="/admin/users">
        <v-list-item-title>Users</v-list-item-title>
      </v-list-item>
      <v-list-item @click="signOut()">Sign out</v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "navbar-account",

  data: () => ({
    items: [
      { label: "My Account", url: "/account" },
      { label: "Registrations", url: "/user/event-registrations" },
    ],
  }),

  methods: {
    signOut() {
      this.$store.commit("signOut");
    },

    profileUrl() {
      var result = "/profile/";
      result += this.$store.state.user.profile_tag;
      return result;
    },
  },
};
</script>
