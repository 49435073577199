<template>
  <div>
    <TimerHeader
      v-if="race['finished']"
      icon="flag-checkered"
      title="Finished"
      subtitle="This paddle has been completed"
    />

    <div v-if="!race['finished']">
      <v-tabs v-model="tab" centered class="mb-8">
        <v-tab v-for="item in tabs" :key="item">{{ item }} </v-tab>
      </v-tabs>

      <div style="position: absolute; left: 12px; z-index: 4">
        <IceDetails v-bind:race="race" v-bind:race_id="race.id" />
      </div>

      <v-btn fab small color="primary" absolute right @click="setColumns()">
        <v-icon color="white">mdi-view-column-outline</v-icon>
      </v-btn>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="tabs[0]">
          <CheckIn v-bind:race="race" v-bind:cols="cols" />
        </v-tab-item>

        <v-tab-item :key="tabs[1]">
          <Timer v-bind:race="race" v-bind:cols="cols" />
        </v-tab-item>

        <v-tab-item :key="tabs[2]">
          <Results v-bind:race="race" v-bind:cols="cols" />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import TimerHeader from "@/components/organiser/paddle-timer/TimerHeader.vue";
import CheckIn from "@/components/organiser/paddle-timer/CheckIn.vue";
import Timer from "@/components/organiser/paddle-timer/Timer.vue";
import Results from "@/components/organiser/paddle-timer/Results.vue";
import IceDetails from "@/components/organiser/paddle-timer/IceDetails.vue";

export default {
  name: "paddleTimer",

  components: {
    TimerHeader,
    CheckIn,
    Timer,
    Results,
    IceDetails,
  },

  data() {
    return {
      tab: null,
      tabs: ["Check in", "Timer", "Results"],
      cols: 12,
      race: {
        id: this.$route.params["id"],
        finished: false,
        start_time: 0,
        registrations: [],
        paddlers: [],
      },
    };
  },

  watch: {
    race: {
      deep: true,
      handler(newRaces) {
        var race_identifier = "race_" + this.race["id"];
        localStorage[race_identifier] = JSON.stringify(newRaces);
      },
    },
  },

  mounted() {
    var race_identifier = "race_" + this.race["id"];
    if (localStorage[race_identifier]) {
      this.race = JSON.parse(localStorage[race_identifier]);
    }
  },

  methods: {
    setColumns() {
      switch (this.cols) {
        case 12:
          this.cols = 6;
          break;
        case 6:
          this.cols = 4;
          break;
        case 4:
          this.cols = 12;
          break;
      }
    },
  },
};
</script>

<style></style>
