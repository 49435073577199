<template>
  <v-container>
    <page-header v-bind:text="pageHeaderText" />
    <div
      v-if="$store.state.user && $store.state.user.profile_tag == profile.tag"
      class="d-flex justify-center"
    >
      <v-btn rounded color="primary" class="mb-5" to="/profile/edit"
        >Add / Edit Nickname</v-btn
      >
      <DownloadBibButton />
    </div>
    <profile-body v-bind:profile="profile" class="mb-5" />
    <profile-results v-bind:results="profile.race_results" />
  </v-container>
</template>

<script>
import PageHeader from "@/components/public/PageHeader.vue";
import ProfileBody from "@/components/public/profile/ProfileBody.vue";
import ProfileResults from "@/components/public/profile/ProfileResults.vue";
import DownloadBibButton from "@/components/public/profile/DownloadBibButton.vue";

export default {
  name: "profile",

  components: {
    PageHeader,
    ProfileBody,
    ProfileResults,
    DownloadBibButton,
  },

  data: () => ({
    error: "",
    profile: {},
  }),

  computed: {
    pageHeaderText: function () {
      if (this.profile.nickname) {
        return this.profile.nickname;
      } else {
        return "Paddler Profile";
      }
    },
  },

  methods: {
    getProfile() {
      var tag = this.$route.params["tag"];
      fetch(`${process.env.VUE_APP_BACKEND_API_URL}/profile/${tag}`, {
        method: "GET",
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.profile = data;
        }
      });
    },
  },

  mounted() {
    this.getProfile();
  },
};
</script>
