<template>
  <div>
    <!-- No paddlers-->
    <TimerHeader
      v-if="race['paddlers'].length <= 0"
      icon="timer-sand"
      title="Waiting for people to check in"
      subtitle="Nobody has checked in to this paddle yet"
    />

    <!-- Not started but has paddlers -->
    <div v-if="race['paddlers'].length > 0 && !raceStarted()">
      <TimerHeader
        icon="flag-variant-outline"
        title="Ready to start"
        :subtitle="`There are ${race['paddlers'].length} paddlers in this event`"
      />

      <v-container>
        <v-row>
          <v-col
            :cols="cols"
            v-for="(paddler, index) in orderedPaddlers"
            :key="index"
          >
            <PaddleTimerButton
              :paddler="paddler"
              icon="flag-variant-outline"
              color="primary"
            />
          </v-col>
        </v-row>
      </v-container>

      <div class="text-center mt-5">
        <v-btn color="success" class="text-center" @click="start"
          >Start the paddle</v-btn
        >
      </div>
    </div>

    <!-- Race in progress -->
    <div v-if="raceStarted()">
      <TimerHeader
        icon="timer-outline"
        v-bind:title="stopwatch"
        subtitle="Paddle is in progress"
      />

      <v-container>
        <v-row>
          <!-- unknown paddler -->
          <v-col :cols="cols">
            <PaddleTimerButton
              @click.native="finishUnknownPaddler()"
              :paddler="{ name: 'unknown paddler', tag: '??????' }"
              icon="flag-checkered"
              color="primary"
            />
          </v-col>

          <!-- paddlers -->
          <v-col
            :cols="cols"
            v-for="(paddler, index) in unfinished()"
            :key="index"
          >
            <PaddleTimerButton
              @click.native="finish(paddler)"
              :paddler="paddler"
              icon="flag-checkered"
              color="primary"
            />
          </v-col>
        </v-row>
      </v-container>

      <div class="text-center mt-5">
        <RestartDialog :race="race" />
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store/index";
import TimerHeader from "@/components/organiser/paddle-timer/TimerHeader.vue";
import PaddleTimerButton from "@/components/organiser/paddle-timer/PaddleTimerButton.vue";
import RestartDialog from "@/components/organiser/paddle-timer/RestartDialog.vue";

export default {
  name: "Timer",
  components: {
    TimerHeader,
    PaddleTimerButton,
    RestartDialog,
  },
  props: ["race", "cols"],
  data() {
    return {
      stopwatch: "00:00:00",
    };
  },

  mounted() {
    this.setStopwatch();
  },

  computed: {
    orderedPaddlers: function () {
      var result = this.race["paddlers"];
      return result.sort((a, b) => a.tag.localeCompare(b.tag));
    },
  },

  methods: {
    unfinished() {
      var result = this.race["paddlers"].filter((paddler) => paddler.time == 0);
      return result.sort((a, b) => a.tag.localeCompare(b.tag));
    },

    raceStarted() {
      return this.race["start_time"] > 0;
    },

    start() {
      this.race["start_time"] = Date.now();
      store.commit("flash", { type: "success", text: "GO!" });
    },

    finish(paddler) {
      paddler.time = this.calculateTime();
      store.commit("flash", {
        type: "success",
        text: `${paddler.first_name} has finished`,
      });
    },

    finishUnknownPaddler() {
      this.race["paddlers"].push({
        id: this.uniqueId(),
        tag: "??????",
        name: "unknown paddler",
        time: this.calculateTime(),
        checkedIn: false,
      });
      store.commit("flash", {
        type: "success",
        text: "Unknown paddler has finished",
      });
    },

    uniqueId() {
      return "_" + Math.random().toString(36).substr(2, 9);
    },

    calculateTime() {
      return Date.now() - this.race["start_time"];
    },

    setStopwatch() {
      setInterval(() => {
        const distance = Date.now() - this.race["start_time"];
        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        var result = "";
        result += String(hours).padStart(2, "0");
        result += ":";
        result += String(minutes).padStart(2, "0");
        result += ":";
        result += String(seconds).padStart(2, "0");
        this.stopwatch = result;
      }, 1000);
    },
  },
};
</script>
