<template>
  <div>
    <div class="page-header mb-6">
      <h1 class="text-h4 mb-3" v-html="text"></h1>
      <div class="page-header-divider"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "page-header",

  props: ["text"],
};
</script>

<style>
.page-header {
  display: flex;
  text-transform: uppercase;
  flex-direction: column;
  align-items: center;
}

.page-header-divider {
  width: 80px;
  height: 4px;
  background: #50a8ab;
}
</style>
