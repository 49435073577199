<template>
  <v-container v-if="content">
    <page-header :text="content.title.rendered" />
    <div class="text-center mb-5">
      <v-icon color="accent">mdi-calendar</v-icon>
      Published {{ prettyDate(content.date_gmt) }}
    </div>
    <v-img :src="content.fimg_url" height="350px" class="mb-5" />
    <div v-html="content.content.rendered"></div>
  </v-container>
</template>

<script>
import PageHeader from "@/components/public/PageHeader.vue";

export default {
  name: "blog",

  components: {
    PageHeader,
  },

  data: () => ({
    content: "",
  }),

  watch: {
    // fetch data when route changes
    $route: "getContent",
  },

  methods: {
    getContent() {
      var url = "";
      url += process.env.VUE_APP_CMS_API_URL;
      url += "/posts/?slug=";
      url += this.$route.params["slug"];
      fetch(url, {
        method: "GET",
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.content = data[0];
        }
      });
    },
  },

  mounted() {
    this.getContent();
  },
};
</script>
