<template>
  <div class="hero py-5">
    <div class="hero-text" v-if="!content">
      <h1 class="text-h4">AquaPaddle – Riverbank to 5k</h1>

      <p class="my-3">
        AquaPaddle is a charity to help people’s mental wellbeing and physical
        health through paddle sport and
        <strong>a free-of-charge, timed 5km paddle</strong> for all abilities,
        on waterways across the country. We would like to grow and support the
        paddleboarding community and complement our BlueSpace activities.
      </p>

      <p class="my-3">
        <router-link to="/about">AquaPaddle (5k) is not a race.</router-link> It
        is a timed social paddle for those who want to improve their fitness and
        wellbeing, open to all paddlers, including paddle boarders, kayakers and
        canoeists.
      </p>

      <p class="text-center">
        <v-btn color="primary" rounded to="/sign-up"
          >Sign up to AquaPaddle here</v-btn
        >
      </p>

      <p class="my-3">
        <router-link to="/bluespace">AquaPaddle – BlueSpace</router-link>
        provides Paddleboarding experiences for those who would not otherwise be
        able to for reasons of cost, lack of confidence or physical ability.
        It’s all about the benefits of having fun on the water.
      </p>

      <p class="text-center">
        <v-btn
          color="primary"
          rounded
          href="https://donate.kindlink.com/AquaPaddle/4769/0"
          target="_blank"
          >Please donate now</v-btn
        >
      </p>

      <p class="my-3">
        For more information,
        <router-link to="/contact">please contact us here</router-link>
      </p>
    </div>

    <div class="hero-text" v-if="content">
      <div v-html="content.content.rendered"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "hero",

  data: () => ({
    content: "",
  }),

  methods: {
    getData() {
      var url = "";
      url += process.env.VUE_APP_CMS_API_URL;
      url += "/pages/117";

      fetch(url, {
        method: "GET",
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.content = data;
        }
      });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("/img/hero.jpg");
  background-size: cover;
  background-position: center;
  filter: brightness(0.4);
}

.hero-text {
  position: relative;
  color: white;
  max-width: 600px;
  padding: 20px;
}
</style>
