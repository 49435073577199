<template>
  <v-form ref="form">
    <v-alert type="error" dark v-if="error" required>{{ this.error }}</v-alert>

    <v-text-field
      label="Enter your new password"
      v-model="password"
      :rules="passwordRules"
      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
      :type="show ? 'text' : 'password'"
      @click:append="show = !show"
    ></v-text-field>

    <v-btn color="primary" @click="submit" class="float-right">Save</v-btn>
  </v-form>
</template>

<script>
import store from "@/store/index";

export default {
  name: "reset-password-form",

  data: () => ({
    password: "",
    error: "",
    show: false,
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length > 2) || "Password must be at least 3 characters",
    ],
  }),

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        var url = "";
        url += process.env.VUE_APP_BACKEND_API_URL;
        url += "/password_resets/";
        url += this.$route.params["id"];

        fetch(url, {
          method: "PATCH",
          body: this.formData(),
        }).then(async (response) => {
          if (!response.ok) {
            this.error =
              "Link has expired, please request another password reset.";
          }

          if (response.ok) {
            store.commit("flash", {
              type: "success",
              text: "Your password has been reset",
            });
            this.$router.push({ name: "SignIn" });
          }
        });
      }
    },

    formData() {
      const form = new FormData();
      form.append("[user]password", this.password);
      return form;
    },
  },
};
</script>
