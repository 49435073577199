<template>
  <div>
    <h2 class="text-h5 mb-3">Results</h2>
    <v-card>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Position</th>
              <th class="text-left">Name</th>
              <th class="text-left">Time</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in results" :key="item.name">
              <td>{{ item.position }}</td>
              <td>
                <router-link
                  :to="{ name: 'Profile', params: { tag: item.profile.tag } }"
                >
                  <template v-if="item.profile.nickname">
                    {{ item.profile.nickname }}
                  </template>
                  <template v-if="!item.profile.nickname">
                    {{ item.profile.tag }}
                  </template>
                </router-link>
              </td>
              <td>{{ pretty_time(item.time) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "race-results",

  props: ["results"],

  methods: {
    pretty_time(ms) {
      const seconds = Math.floor((ms / 1000) % 60);
      const minutes = Math.floor((ms / 1000 / 60) % 60);
      const hours = Math.floor((ms / 1000 / 3600) % 24);

      const humanize = [
        hours.toLocaleString("en-us", { minimumIntegerDigits: 2 }),
        minutes.toLocaleString("en-us", { minimumIntegerDigits: 2 }),
        seconds.toLocaleString("en-us", { minimumIntegerDigits: 2 }),
      ];

      return humanize.join(":");
    },
  },
};
</script>
