<template>
  <v-btn rounded color="primary" class="ms-3" @click="downloadTag"
    >Download tag</v-btn
  >
</template>

<script>
export default {
  name: "download-bib-button",

  methods: {
    downloadTag() {
      fetch(
        `${process.env.VUE_APP_BACKEND_API_URL}/user/profile/number-plate`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      ).then(async (response) => {
        if (!response.ok) {
          //this.error = data;
        }

        if (response.ok) {
          const blob = await response.blob();
          var file = window.URL.createObjectURL(blob);
          var anchor = document.createElement("a");
          anchor.href = file;
          anchor.download = "AquaPaddle_Number_Plate.png";
          anchor.click();
        }
      });
    },
  },
};
</script>
