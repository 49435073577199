var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('page-header',{attrs:{"text":"Incident Reports"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'AdminIncidentReport', params: { id: item.id } }}},[_vm._v(_vm._s(item.id))])]}},{key:"item.injury",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.injury ? "Yes" : "No")+" ")]}},{key:"item.incident",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.incident ? "Yes" : "No")+" ")]}},{key:"item.followup",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.followup ? "Yes" : "No")+" ")]}}])}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.exportCsv()}}},[_vm._v("Download CSV")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }