<template>
  <v-container>
    <page-header text="My Account" />
    <edit-user-form />
  </v-container>
</template>

<script>
import PageHeader from "@/components/public/PageHeader.vue";
import EditUserForm from "@/components/account/EditUserForm.vue";

export default {
  name: "account",

  components: {
    PageHeader,
    EditUserForm,
  },
};
</script>
