<template>
  <v-container>
    <page-header :text="content.title.rendered"></page-header>
    <div v-html="content.content.rendered"></div>
  </v-container>
</template>

<script>
import PageHeader from "@/components/public/PageHeader.vue";

export default {
  name: "supporters",

  components: {
    PageHeader,
  },

  data: () => ({
    content: "",
  }),

  methods: {
    getData() {
      var url = "";
      url += process.env.VUE_APP_CMS_API_URL;
      url += "/pages/451";

      fetch(url, {
        method: "GET",
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.content = data;
        }
      });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
