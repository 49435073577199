<template>
  <router-link to="/">
    <img src="/img/logo.png" height="60" />
  </router-link>
</template>

<script>
export default {
  name: "navbar-logo",
};
</script>
