<template>
  <v-container>
    <page-header text="Sign in" />
    <sign-in-form />
  </v-container>
</template>

<script>
import PageHeader from "@/components/public/PageHeader.vue";
import SignInForm from "@/components/public/SignInForm.vue";

export default {
  name: "sign-in",

  components: {
    PageHeader,
    SignInForm,
  },
};
</script>
