<template>
  <v-container>
    <page-header text="Sign up" />
    <sign-up-form />
  </v-container>
</template>

<script>
import PageHeader from "@/components/public/PageHeader.vue";
import SignUpForm from "@/components/public/SignUpForm.vue";

export default {
  name: "sign-up",

  components: {
    PageHeader,
    SignUpForm,
  },
};
</script>
