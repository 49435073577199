<template>
  <div>
    <v-card class="d-none d-md-block">
      <v-list-item
        v-for="item in pages"
        :key="item.ID"
        :to="'/volunteer/' + item.slug"
      >
        <v-list-item-content>
          <v-list-item-title>
            <v-icon>mdi-menu-right</v-icon>
            {{ item.title.rendered }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-card>

    <v-select
      class="d-md-none"
      :items="pages"
      item-text="title.rendered"
      item-value="slug"
      v-on:change="goToPage"
      label="Volunteer"
      outlined
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "VoluteerChildPageList",

  data: () => ({
    pages: [],
  }),

  methods: {
    goToPage(selected) {
      this.$router.push("/volunteer/" + selected);
    },

    getData() {
      var url = "";
      url += process.env.VUE_APP_CMS_API_URL;
      url += "/pages?parent=10&per_page=100";

      fetch(url, {
        method: "GET",
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          const volunteerPage = {
            title: { rendered: "Volunteer" },
            slug: "volunteer",
          };
          const childPages = data.sort((a, b) => a.menu_order - b.menu_order);
          this.pages.push(volunteerPage);
          this.pages = this.pages.concat(childPages);
        }
      });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
