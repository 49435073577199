<template>
  <v-snackbar v-if="flash" v-model="flash" v-bind:color="style.color">
    <v-icon class="mr-2">
      {{ style.icon }}
    </v-icon>
    {{ text }}
  </v-snackbar>
</template>

<script>
export default {
  name: "flash-message",

  data: () => ({
    flash: false,
    type: "",
    text: "",
    success: {
      icon: "mdi-check-circle",
      color: "green",
    },
    error: {
      icon: "mdi-alert-circle",
      color: "red",
    },
  }),

  watch: {
    "$store.state.flash": function (flash) {
      this.flash = flash;
      this.type = flash.type;
      this.text = flash.text;
    },
  },

  computed: {
    style() {
      return this[this.type];
    },
  },
};
</script>
