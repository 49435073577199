<template>
  <div>
    <v-card class="text-center mb-5" color="primary" dark flat>
      <v-card-text>
        <span class="text-h6">
          {{ race.description }}
        </span>
      </v-card-text>
    </v-card>

    <div v-for="item in details" :key="item.name">
      <v-row class="py-2">
        <v-col class="text-right font-weight-bold">
          {{ item.name }}
        </v-col>
        <v-col>
          {{ race[item.value] }}
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>
    <v-row class="py-2" v-if="race.location">
      <v-col class="text-right font-weight-bold"> Location </v-col>
      <v-col>
        <router-link :to="{ path: '/locations/' + race.location.id }">
          {{ race.location.name }}
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "race-details",

  props: ["race"],

  data: () => ({
    details: [
      { name: "Date", value: "date" },
      { name: "Start time", value: "time" },
      { name: "Arrival time", value: "arrival_time" },
      { name: "Meeting point", value: "meeting_point" },
    ],
  }),
};
</script>

<style>
.race-detail {
  margin-bottom: 14px;
}

.race-detail div {
  width: 120px;
  display: inline-block;
  margin-right: 10px;
  text-align: right;
}
</style>
