<template>
  <v-container>
    <paddle-timer />
  </v-container>
</template>

<script>
import PaddleTimer from "@/components/organiser/paddle-timer/PaddleTimer.vue";

export default {
  name: "timer",

  components: {
    PaddleTimer,
  },
};
</script>
