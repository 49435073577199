<template>
  <v-container class="justify-center">
    <page-header text="Paddle Organiser" />
    <Menu />
    <h2 class="text-h5 mb-6 text-center">Upcoming Events</h2>
    <p class="text-center" v-if="races.length == 0">
      You have no upcoming paddles organised.
    </p>

    <EventCard v-for="race in races" :race="race" :key="race.id" />
  </v-container>
</template>

<script>
import PageHeader from "@/components/public/PageHeader.vue";
import EventCard from "@/components/organiser/EventCard.vue";
import Menu from "@/components/organiser/Menu.vue";

export default {
  name: "Upcoming",
  components: {
    PageHeader,
    EventCard,
    Menu,
  },
  data() {
    return {
      races: [],
      error: "",
    };
  },
  mounted() {
    this.getRaces();
  },

  methods: {
    getRaces() {
      var url = "";
      url += process.env.VUE_APP_BACKEND_API_URL;
      url += "/organiser/races/";

      fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.races = data;
        }
      });
    },
  },
};
</script>
