<template>
  <v-card color="primary" dark>
    <v-card-title class="justify-space-between">
      Upcoming Paddles

      <v-btn small rounded color="accent" to="/paddles">See all</v-btn>
    </v-card-title>

    <v-divider></v-divider>

    <v-list color="primary">
      <v-list-item
        v-for="race in races.slice(0, 5)"
        :key="race.id"
        :to="{ name: 'Race', params: { id: race.id } }"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ race.name }}
          </v-list-item-title>

          <v-list-item-subtitle>
            {{ prettyDate(race.scheduled_start_time) }}
            @
            {{ race.time }}
            at
            {{ race.location.name }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "upcoming-races",

  data: () => ({
    races: [],
  }),

  methods: {
    getData() {
      fetch(`${process.env.VUE_APP_BACKEND_API_URL}/races`, {
        method: "GET",
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.races = data;
        }
      });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
