<template>
  <v-container>
    <page-header text="Forgot Password" />
    <forgot-password-form />
  </v-container>
</template>

<script>
import PageHeader from "@/components/public/PageHeader.vue";
import ForgotPasswordForm from "@/components/public/ForgotPasswordForm.vue";

export default {
  name: "ForgotPassword",

  components: {
    PageHeader,
    ForgotPasswordForm,
  },
};
</script>
