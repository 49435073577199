<template>
  <v-container>
    <page-header text="Incident Report" />

    <v-simple-table v-if="data.id">
      <template v-slot:default>
        <tbody>
          <tr>
            <th>Incident ID</th>
            <td>{{ data.id }}</td>
          </tr>
          <tr>
            <th>Created</th>
            <td>{{ prettyDate(data.created_at) }}</td>
          </tr>
          <tr>
            <th>Event</th>
            <td>
              <router-link
                :to="{ name: 'Race', params: { id: data.race.id } }"
                >{{ data.race.name }}</router-link
              >
            </td>
          </tr>
          <tr>
            <th>Incident</th>
            <td>{{ data.incident ? "Yes" : "No" }}</td>
          </tr>
          <tr>
            <th>Injury</th>
            <td>{{ data.injury ? "Yes" : "No" }}</td>
          </tr>
          <tr>
            <th>Assistance</th>
            <td>{{ data.assistance }}</td>
          </tr>
          <tr>
            <th>Follow up requested</th>
            <td>{{ data.followup ? "Yes" : "No" }}</td>
          </tr>
          <tr>
            <th>Type</th>
            <td>{{ data.category ? data.category : "N/A" }}</td>
          </tr>
          <tr>
            <th>Details</th>
            <td>{{ data.details ? data.details : "No" }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>
<script>
import PageHeader from "@/components/public/PageHeader.vue";
export default {
  name: "AdminIncidentReport",

  components: {
    PageHeader,
  },

  data() {
    return {
      data: {},
      error: "",
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      var url = "";
      url += process.env.VUE_APP_BACKEND_API_URL;
      url += "/admin/incident_reports/";
      url += this.$route.params["id"];

      fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.data = data;
        }
      });
    },
  },
};
</script>
