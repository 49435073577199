<template>
  <v-container>
    <page-header text="Finished Paddles" />
    <finished />
  </v-container>
</template>

<script>
import PageHeader from "@/components/public/PageHeader.vue";
import Finished from "@/components/public/races/Finished.vue";

export default {
  name: "finished-races",

  components: {
    PageHeader,
    Finished,
  },
};
</script>
