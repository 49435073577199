<template>
  <v-container>
    <page-header text="Edit Location" />

    <v-form v-if="location.id">
      <v-checkbox
        label="Enabled"
        class="mb-8"
        outlined
        persistent-hint
        v-model="location.enabled"
        hint="Enable this location to allow organisers to create events for it"
        :error-messages="error['enabled']"
      ></v-checkbox>

      <v-text-field
        label="Name"
        class="mb-5"
        outlined
        v-model="location.name"
        :error-messages="error['name']"
      ></v-text-field>

      <v-text-field
        label="Description"
        class="mb-5"
        outlined
        v-model="location.description"
        :error-messages="error['description']"
      ></v-text-field>

      <v-text-field
        label="what3words"
        class="mb-5"
        outlined
        persistent-hint
        v-model="location.what3words"
        hint="Don't include the slashes, just the words and dots eg: parts.most.gifts"
        :error-messages="error['what3words']"
      ></v-text-field>

      <v-textarea
        label="Embedded Map"
        class="mt-5 mb-5"
        rows="10"
        outlined
        persistent-hint
        v-model="location.embedded_map"
        hint="A block of HTML code taken from maps.google.co.uk. Once you have
		  the pin in position click 'share' > 'embed a map' and choose a custom
		  size of 1200x350."
        :error-messages="error['details']"
      ></v-textarea>
    </v-form>

    <div class="d-flex">
      <v-btn color="primary" @click="submit">Save</v-btn>
      <v-btn class="ml-auto" to="/admin/locations">Cancel</v-btn>
    </div>
  </v-container>
</template>
<script>
import PageHeader from "@/components/public/PageHeader.vue";
import store from "@/store/index";
export default {
  name: "AdminLocationsEdit",

  components: {
    PageHeader,
  },

  data() {
    return {
      location: {},
      error: "",
    };
  },
  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      var url = "";
      url += process.env.VUE_APP_BACKEND_API_URL;
      url += "/admin/locations/";
      url += this.$route.params["id"];

      fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.location = data;
        }
      });
    },

    submit() {
      const form = new FormData();
      form.append("[location]name", this.location.name);
      form.append("[location]enabled", this.location.enabled);
      form.append("[location]description", this.location.description);
      form.append("[location]what3words", this.location.what3words);
      form.append("[location]embedded_map", this.location.embedded_map);

      var url = "";
      url += process.env.VUE_APP_BACKEND_API_URL;
      url += "/admin/locations/";
      url += this.$route.params["id"];

      fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        method: "PATCH",
        body: form,
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
          store.commit("flash", {
            type: "error",
            text: "Something went wrong",
          });
        }

        if (response.ok) {
          this.$router.push({ name: "AdminLocations" });
          store.commit("flash", { type: "success", text: "Saved" });
        }
      });
    },
  },
};
</script>
