<template>
  <v-footer color="primary" dark>
    <v-container class="text-center">
      &copy; AquaPaddle {{ year }} All Rights Reserved |
      <router-link to="/terms">Terms</router-link>
      |
      <router-link to="/privacy">Privacy</router-link>
      |
      <router-link to="/safeguarding">Safeguarding</router-link>
      |
      <a
        href="https://register-of-charities.charitycommission.gov.uk/charity-search/-/charity-details/5178590/charity-overview"
        target="_blank"
        >Registered charity number 1194714</a
      >
    </v-container>
  </v-footer>
</template>
<script>
export default {
  name: "app-footer",

  data: () => ({
    year: new Date().getFullYear(),
  }),
};
</script>

<style>
.v-footer {
  margin-top: auto;
  font-size: 80%;
  padding: 40px 0 !important;
}

footer a {
  color: white !important;
}
</style>
