<template>
  <div>
    <page-header text="Our Locations" />
    <p class="text-center">
      So far events have taken place at Ditton Beach and Hampton Warf on the
      River Thames, but new locations are opening up shortly. Watch this space.
    </p>
    <v-row>
      <v-col
        cols="12"
        class="col-sm-6 col-md-4 col-lg-3"
        v-for="item in locations"
        :key="item.id"
      >
        <v-card
          class="text-center"
          style="height: 100%"
          :to="{ name: 'Location', params: { id: item.id } }"
        >
          <v-card-text>
            <v-icon color="accent" class="d-block"> mdi-map-marker </v-icon>

            <h5 class="font-weight-bold text-subtitle-1">
              {{ item.name }}
            </h5>

            <span class="text-caption">
              {{ item.nearest_place }}
            </span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PageHeader from "@/components/public/PageHeader.vue";

export default {
  name: "locations",

  components: {
    PageHeader,
  },

  data: () => ({
    locations: [],
  }),

  methods: {
    getData() {
      fetch(`${process.env.VUE_APP_BACKEND_API_URL}/locations`, {
        method: "GET",
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.locations = data;
        }
      });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
