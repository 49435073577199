import Vue from "vue";
import Vuex from "vuex";
import jwt_decode from "jwt-decode";
import router from "../router";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: "",
    flash: "",
  },

  plugins: [createPersistedState()],

  mutations: {
    signIn(state, token) {
      localStorage.setItem("token", token);
      state.user = jwt_decode(token);
    },

    signOut(state) {
      localStorage.removeItem("token");
      state.user = "";
      router.push({ name: "SignIn" });
    },

    flash(state, data) {
      state.flash = data;
    },
  },

  actions: {},

  modules: {},
});
