<template>
  <v-container style="max-width: 500px">
    <page-header text="Edit Event" />
    <Form :race="race" :error="error" />
    <div class="d-flex">
      <v-btn color="primary" @click="submit">Save</v-btn>
      <v-btn class="ml-auto" to="/organiser">Cancel</v-btn>
      <v-btn class="ml-5" @click="destroy()">Delete</v-btn>
    </div>
  </v-container>
</template>

<script>
import PageHeader from "@/components/public/PageHeader.vue";
import Form from "@/components/organiser/Form.vue";
import store from "@/store/index";

export default {
  name: "OrganiserEdit",
  components: {
    PageHeader,
    Form,
  },
  data() {
    return {
      error: "",
      race: {},
    };
  },
  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      var url = "";
      url += process.env.VUE_APP_BACKEND_API_URL;
      url += "/organiser/races/";
      url += this.$route.params["id"];

      fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.race = data;
        }
      });
    },

    submit() {
      const form = new FormData();
      form.append("[race]name", this.race.name);
      form.append("[race]description", this.race.description);
      form.append("[race]meeting_point", this.race.meeting_point);
      form.append("[race]max_registrations", this.race.max_registrations);
      form.append("[race]arrival_time", this.race.arrival_time);
      form.append("[race]scheduled_start_time", this.race.scheduled_start_time);
      form.append("[race]location_id", this.race.location_id);

      var url = "";
      url += process.env.VUE_APP_BACKEND_API_URL;
      url += "/organiser/races/";
      url += this.$route.params["id"];

      fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        method: "PATCH",
        body: form,
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
          store.commit("flash", {
            type: "error",
            text: "Something went wrong",
          });
        }

        if (response.ok) {
          this.$router.push({ name: "Organiser" });
          store.commit("flash", { type: "success", text: "Saved" });
        }
      });
    },

    destroy() {
      var url = "";
      url += process.env.VUE_APP_BACKEND_API_URL;
      url += "/organiser/races/";
      url += this.$route.params["id"];

      if (confirm("Are you sure you want to delete this event?")) {
        fetch(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          method: "DELETE",
        }).then(async (response) => {
          const data = await response.json();

          if (!response.ok) {
            this.error = data;
            store.commit("flash", {
              type: "error",
              text: "Something went wrong",
            });
          }

          if (response.ok) {
            this.$router.push({ name: "Organiser" });
            store.commit("flash", { type: "success", text: "Deleted" });
          }
        });
      }
    },
  },
};
</script>
