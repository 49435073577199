<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6" lg="4" v-for="item in content" :key="item.index">
        <blog-card :blog="item" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BlogCard from "@/components/public/blog/BlogCard.vue";

export default {
  name: "blog-index",

  components: {
    BlogCard,
  },

  data: () => ({
    content: "",
  }),

  methods: {
    getData() {
      var url = "";
      url += process.env.VUE_APP_CMS_API_URL;
      url += "/posts";

      fetch(url, {
        method: "GET",
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.content = data;
        }
      });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
