<template>
  <v-container style="max-width: 500px">
    <page-header text="Edit User" />
    <v-form>
      <v-text-field
        v-model="user.email"
        label="Email address"
        :error-messages="error['email']"
      ></v-text-field>

      <v-text-field
        v-model="user.first_name"
        label="First name"
        :error-messages="error['first_name']"
      ></v-text-field>

      <v-text-field
        v-model="user.last_name"
        label="Last name"
        :error-messages="error['last_name']"
      ></v-text-field>

      <v-checkbox
        v-model="user.organiser"
        label="Organiser"
        :error-messages="error['organiser']"
      ></v-checkbox>

      <v-checkbox
        v-model="user.locked"
        label="Locked"
        :error-messages="error['locked']"
      ></v-checkbox>
    </v-form>
    <div class="d-flex">
      <v-btn color="primary" @click="submit">Save</v-btn>
      <v-btn class="ml-auto" to="/admin/users">Back</v-btn>
    </div>
  </v-container>
</template>

<script>
import PageHeader from "@/components/public/PageHeader.vue";
import store from "@/store/index";

export default {
  name: "AdminUserEdit",
  components: {
    PageHeader,
  },
  data() {
    return {
      error: "",
      user: {},
    };
  },
  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      var url = "";
      url += process.env.VUE_APP_BACKEND_API_URL;
      url += "/admin/users/";
      url += this.$route.params["id"];

      fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.user = data;
        }
      });
    },

    submit() {
      const form = new FormData();
      form.append("[user]first_name", this.user.first_name);
      form.append("[user]last_name", this.user.last_name);
      form.append("[user]email", this.user.email);
      form.append("[user]organiser", this.user.organiser);
      form.append("[user]locked", this.user.locked);

      var url = "";
      url += process.env.VUE_APP_BACKEND_API_URL;
      url += "/admin/users/";
      url += this.$route.params["id"];

      fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        method: "PATCH",
        body: form,
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
          store.commit("flash", {
            type: "error",
            text: "Something went wrong",
          });
        }

        if (response.ok) {
          store.commit("flash", { type: "success", text: "Saved" });
        }
      });
    },
  },
};
</script>
