<template>
  <v-card :to="'/blog/' + blog.slug">
    <v-img
      :src="blog.fimg_url"
      height="200px"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.7)"
      class="white--text align-end"
    >
      <v-card-title>{{ blog.title.rendered }}</v-card-title>
      <v-card-subtitle>
        {{ prettyDate(blog.date_gmt) }}
      </v-card-subtitle>
    </v-img>
  </v-card>
</template>

<script>
export default {
  name: "blog-card",

  props: ["blog"],
};
</script>

<style scoped>
.v-card__title {
  word-break: break-word !important;
}
</style>
