<template>
  <v-form>
    <v-text-field
      label="Name"
      class="mb-5"
      outlined
      persistent-hint
      v-model="race.name"
      hint="Give your event an inviting name to attract new paddlers"
      :error-messages="error['name']"
    ></v-text-field>

    <v-text-field
      label="Description"
      class="mb-5"
      outlined
      persistent-hint
      v-model="race.description"
      hint="Describe your event"
      :error-messages="error['description']"
    ></v-text-field>

    <v-select
      label="Location"
      class="mb-5"
      outlined
      persistent-hint
      v-model="race.location_id"
      :items="locations"
      hint="Choose the location of your event"
      :error-messages="error['location']"
      item-text="name"
      item-value="id"
    ></v-select>

    <v-text-field
      class="mb-5"
      label="Max registrations"
      type="number"
      min="5"
      max="20"
      outlined
      persistent-hint
      v-model="race.max_registrations"
      hint="What is the maximum number of paddlers your event can accomodate?"
      :error-messages="error['max_registrations']"
    ></v-text-field>

    <v-text-field
      label="Meeting point"
      class="mb-5"
      outlined
      persistent-hint
      v-model="race.meeting_point"
      hint='For example: "Outside the rowing club" or "The river bank by the starting point" etc'
      :error-messages="error['meeting_point']"
    ></v-text-field>

    <v-text-field
      label="Arrival time"
      class="mb-5"
      type="datetime-local"
      persistent-hint
      outlined
      v-model="race.arrival_time"
      :error-messages="error['arrival_time']"
      hint="We recommend setting this to 15 - 30 minutes before the start time"
    ></v-text-field>

    <v-text-field
      label="Start time"
      class="mb-5"
      type="datetime-local"
      persistent-hint
      outlined
      v-model="race.scheduled_start_time"
      :error-messages="error['scheduled_start_time']"
      hint="When is the planned start time for this event?"
    ></v-text-field>
  </v-form>
</template>

<script>
export default {
  name: "Form",
  props: ["race", "error"],
  data() {
    return {
      locations: [],
    };
  },
  mounted() {
    this.getLocations();
  },

  methods: {
    getLocations() {
      fetch(`${process.env.VUE_APP_BACKEND_API_URL}/locations`, {
        method: "GET",
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.locations = data;
        }
      });
    },
  },
};
</script>
