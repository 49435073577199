<template>
  <v-container>
    <homepage-flash-message />
    <hero />

    <v-row class="my-3">
      <v-col>
        <upcoming-races />
      </v-col>
      <v-col>
        <finished-races />
      </v-col>
    </v-row>

    <page-header class="mt-8" text="AquaPaddle – “Riverbank to 5k”" />

    <p>
      AquaPaddle is a free, regular, 5km timed paddle. Like the famous Run in
      the Park; it is not a race. It is a social paddle for those who want to
      improve their fitness and wellbeing, open to all paddlers, including
      paddle boarders, kayakers and canoeists.
    </p>

    <p>
      The first AquaPaddle was run on Saturday 17th July 2021, and the course
      was from Ditton Beach, Albany Reach Open Space, around Ravens Ait and
      finishing at The Albany Pub, Thames Ditton.
    </p>

    <p>
      But if you want more information,
      <a href="mailto:paddle@aquapaddle.org">please contact us here.</a>
    </p>

    <locations />
  </v-container>
</template>

<script>
import HomepageFlashMessage from "@/components/public/home/HomepageFlashMessage.vue";
import Hero from "@/components/public/home/Hero.vue";
import UpcomingRaces from "@/components/public/home/UpcomingRaces.vue";
import FinishedRaces from "@/components/public/home/FinishedRaces.vue";
import Locations from "@/components/public/home/Locations.vue";
import PageHeader from "@/components/public/PageHeader.vue";

export default {
  name: "Home",
  components: {
    Hero,
    HomepageFlashMessage,
    UpcomingRaces,
    FinishedRaces,
    Locations,
    PageHeader,
  },
};
</script>
