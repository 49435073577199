<template>
  <v-container style="max-width: 500px">
    <page-header text="New Incident Report" />
    <h2 class="text-h5 text-center">
      {{ race.name }}
    </h2>
    <p class="text-center">
      {{ race.date }}
      {{ race.time }}
    </p>

    <v-form>
      <v-radio-group label="Any incidents?" v-model="incident_report.incident">
        <v-radio label="No" :value="false"></v-radio>
        <v-radio label="Yes" :value="true"></v-radio>
      </v-radio-group>

      <v-radio-group label="Any injurys?" v-model="incident_report.injury">
        <v-radio label="No" :value="false"></v-radio>
        <v-radio label="Yes" :value="true"></v-radio>
      </v-radio-group>

      <v-radio-group
        label="Any help required?"
        v-model="incident_report.assistance"
      >
        <v-radio label="No" value="No"></v-radio>
        <v-radio label="First Aid" value="First Aid"></v-radio>
        <v-radio
          label="Medical Professional"
          value="Medical Professional"
        ></v-radio>
        <v-radio label="Emergency Services" value="Emergeny Services"></v-radio>
      </v-radio-group>

      <v-radio-group
        label="Follow up required?"
        v-model="incident_report.followup"
      >
        <v-radio label="No" :value="false"></v-radio>
        <v-radio label="Yes" :value="true"></v-radio>
      </v-radio-group>

      <v-radio-group label="Incident type" v-model="incident_report.category">
        <v-radio label="N/A" value="N/A"></v-radio>
        <v-radio label="Fall in" value="Fall in"></v-radio>
        <v-radio label="Injury" value="Injury"></v-radio>
        <v-radio label="Medical Condition" value="Medical Condition"></v-radio>
        <v-radio
          label="Emergency Service/Third Party Call Out"
          value="Emergency Service/Third Party Call Out"
        ></v-radio>
        <v-radio
          label="Other Waterway User"
          value="Other Waterway User"
        ></v-radio>
        <v-radio label="Access/Navigation" value="Access/Navigation"></v-radio>
        <v-radio
          label="Angling Dispute Environmental"
          value="Angling Dispute Environmental"
        ></v-radio>
        <v-radio label="Pollution" value="Pollution"></v-radio>
        <v-radio label="Wildlife" value="Wildlife"></v-radio>
        <v-radio label="Equipment Failure" value="Equipment Failure"></v-radio>
        <v-radio
          label="Report Under RIDDOR"
          value="Report Under RIDDOR"
        ></v-radio>
        <v-radio label="Near miss" value="Near miss"></v-radio>
        <v-radio label="COVID-19" value="COVID-19"></v-radio>
        <v-radio label="Other" value="Other"></v-radio>
      </v-radio-group>

      <v-form>
        <v-textarea
          label="Details"
          class="mt-5 mb-5"
          rows="10"
          outlined
          persistent-hint
          v-model="incident_report.details"
          hint="Add any important notes or details about the incident here"
          :error-messages="error['details']"
        ></v-textarea>
      </v-form>
    </v-form>

    <div class="d-flex">
      <v-btn color="primary" @click="submit">Save</v-btn>
      <v-btn class="ml-auto" to="/organiser">Cancel</v-btn>
    </div>
  </v-container>
</template>

<script>
import store from "@/store/index";
import PageHeader from "@/components/public/PageHeader.vue";

export default {
  name: "NewIncidientReport",

  components: {
    PageHeader,
  },

  data() {
    return {
      error: "",
      race: {},
      incident_report: {
        id: "",
        incident: false,
        injury: false,
        assistance: "No",
        followup: false,
        category: "",
        details: "",
      },
    };
  },

  mounted() {
    this.getRace();
  },

  methods: {
    submit() {
      const form = new FormData();
      form.append("[incident_report]race_id", this.race.id);
      form.append("[incident_report]incident", this.incident_report.incident);
      form.append("[incident_report]injury", this.incident_report.injury);
      form.append(
        "[incident_report]assistance",
        this.incident_report.assistance
      );
      form.append("[incident_report]followup", this.incident_report.followup);
      form.append("[incident_report]details", this.incident_report.details);
      form.append("[incident_report]category", this.incident_report.category);

      fetch(
        `${process.env.VUE_APP_BACKEND_API_URL}/organiser/incident_reports`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          method: "POST",
          body: form,
        }
      ).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
          store.commit("flash", {
            type: "error",
            text: "Something went wrong",
          });
        }

        if (response.ok) {
          this.$router.push({ name: "Organiser" });
          store.commit("flash", { type: "success", text: "Saved" });
        }
      });
    },

    getRace() {
      var url = "";
      url += process.env.VUE_APP_BACKEND_API_URL;
      url += "/races/";
      url += this.$route.params["id"];

      fetch(url, {
        method: "GET",
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.race = data;
        }
      });
    },
  },
};
</script>

<style scoped>
.v-input >>> .v-label:first-child {
  font-size: 20px !important;
}
</style>
