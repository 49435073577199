<template>
  <div>
    <race-details v-bind:race="race" />

    <enter-race-button
      v-if="this.$store.state.user && race.registering && !race.finished"
      v-bind:race_id="race.id"
    />

    <race-results v-if="race.finished" v-bind:results="race.results" />
  </div>
</template>

<script>
import RaceDetails from "@/components/public/races/show/RaceDetails.vue";
import EnterRaceButton from "@/components/public/races/show/EnterRaceButton.vue";
import RaceResults from "@/components/public/races/show/RaceResults.vue";

export default {
  name: "show",

  props: ["race"],

  components: {
    RaceDetails,
    EnterRaceButton,
    RaceResults,
  },
};
</script>

<style>
.race-detail {
  margin-bottom: 14px;
}

.race-detail div {
  width: 120px;
  display: inline-block;
  margin-right: 10px;
  text-align: right;
}
</style>
