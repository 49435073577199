<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        @click="getRegistrations"
        class="ice-button"
        fab
        small
        color="primary"
      >
        <v-icon color="white">mdi-card-account-phone</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Emergency Info
      </v-card-title>
      <v-card-text v-if="!registrations.length">
        <div class="text-center mt-5">
          <v-icon color="accent">mdi-timer-sand</v-icon>
          <h4>Waiting for registrations</h4>
          <p>Nobody has signed up to take part in this event yet</p>
        </div>
      </v-card-text>

      <v-card-text v-if="registrations.length">
        <p class="mt-3">
          Showing emergency details for all who have registered
        </p>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Name</th>
                <th>ICE Name</th>
                <th>ICE Number</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="paddler in registrations" :key="paddler.id">
                <td>{{ paddler.first_name }} {{ paddler.last_name }}</td>
                <td>{{ paddler.emergency_contact_name }}</td>
                <td>
                  <a :href="'tel:' + paddler.emergency_contact_number">
                    {{ paddler.emergency_contact_number }}
                  </a>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "@/store/index";

export default {
  name: "IceDetails",

  props: ["race", "race_id"],

  data() {
    return {
      dialog: false,
      registrations: [],
    };
  },

  methods: {
    getRegistrations() {
      var url = "";
      url += process.env.VUE_APP_BACKEND_API_URL;
      url += "/organiser/races/";
      url += this.race_id;
      url += "/registrations";

      fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
          store.commit("flash", {
            type: "error",
            text: "Couldn't fetch emergency info",
          });
        }

        if (response.ok) {
          this.registrations = data;
        }
      });
    },
  },
};
</script>
