<template>
  <v-container v-if="content">
    <page-header :text="content.title.rendered"></page-header>

    <v-row>
      <v-col cols="12" md="3">
        <VolunteerChildPageList />
      </v-col>
      <v-col>
        <div v-html="content.content.rendered"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/public/PageHeader.vue";
import VolunteerChildPageList from "@/components/public/VolunteerChildPageList.vue";

export default {
  name: "volunteer",

  components: {
    PageHeader,
    VolunteerChildPageList,
  },

  data: () => ({
    content: "",
  }),

  watch: {
    // fetch data when route changes
    $route: "getContent",
  },

  methods: {
    getContent() {
      if (this.$route.params["slug"]) {
        this.getChildPage();
      } else {
        this.getMainPage();
      }
    },

    getMainPage() {
      var url = "";
      url += process.env.VUE_APP_CMS_API_URL;
      url += "/pages/10";

      fetch(url, {
        method: "GET",
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.content = data;
        }
      });
    },

    getChildPage() {
      var url = "";
      url += process.env.VUE_APP_CMS_API_URL;
      url += "/pages/?slug=";
      url += this.$route.params["slug"];
      fetch(url, {
        method: "GET",
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.content = data[0];
        }
      });
    },
  },

  mounted() {
    this.getContent();
  },
};
</script>
