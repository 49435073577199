<template>
  <v-card
    class="mb-5 text-center pa-3"
    color="primary"
    dark
    @click="$router.push({ name: 'Race', params: { id: race.id } })"
  >
    <v-card-title class="justify-center mb-3 text-h5">
      {{ race.name }}
    </v-card-title>

    <v-card-subtitle>
      <span class="mr-3">
        <v-icon>mdi-calendar</v-icon>
        {{ race.date }}
      </span>
      <span class="mr-3">
        <v-icon>mdi-clock</v-icon>
        {{ race.time }}
      </span>
      <span class="mr-3">
        <v-icon>mdi-map-marker</v-icon>
        {{ race.location.name }}
      </span>
      <span class="mr-3">
        <v-icon>mdi-map</v-icon>
        {{ race.distance }}k
      </span>
    </v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: "index-item",

  props: ["race"],
};
</script>
