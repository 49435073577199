<template>
  <div>
    <h4 class="text-h5">Results</h4>
    <v-simple-table v-if="results">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Date</th>
            <th class="text-left">Position</th>
            <th class="text-left">Paddle</th>
            <th class="text-left">Time</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in results" :key="item.id">
            <td>{{ prettyDate(item.race_date) }}</td>
            <td>{{ item.position }}</td>

            <td>
              <router-link :to="{ name: 'Race', params: { id: item.race_id } }">
                {{ item.race_name }}
              </router-link>
            </td>
            <td>{{ prettyTime(item.time) }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "profile-results",

  props: ["results"],
};
</script>
