<template>
  <v-app>
    <div id="app">
      <flash-message />
      <drawer />
      <navbar />
      <router-view style="margin-bottom: 50px" />
      <app-footer />
    </div>
  </v-app>
</template>

<script>
import FlashMessage from "@/components/FlashMessage";
import Drawer from "@/components/public/Drawer";
import Navbar from "@/components/public/navbar/Navbar";
import AppFooter from "@/components/public/AppFooter";

export default {
  name: "App",

  components: {
    FlashMessage,
    Drawer,
    Navbar,
    AppFooter,
  },
};
</script>

<style>
#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

@media (min-width: 1904px) {
  .container {
    max-width: 1185px !important;
  }
}

.wp-block-image img {
  height: 100%;
  max-width: 100%;
}

.wp-block-image figcaption {
  text-align: center;
  font-size: 80%;
  margin-bottom: 1rem;
}

.wp-block-button {
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
}

.wp-block-button a {
  color: white !important;
  text-transform: uppercase;
  font-size: 0.875rem;
  border-radius: 28px;
  padding: 10px 16px;
  text-decoration: none;
  font-weight: 500;
}

.has-pale-pink-background-color {
  background-color: #e93f66;
}

.has-pale-cyan-blue-background-color {
  background-color: #50a8ab;
}

.has-text-align-left {
  text-align: left;
}
.has-text-align-right {
  text-align: right;
}
.has-text-align-center {
  text-align: center;
}
</style>
