<template>
  <v-form>
    <v-text-field
      v-model="profile.nickname"
      label="Nickname"
      :error-messages="error['nickname']"
    ></v-text-field>

    <v-btn color="primary" @click="submit">Save</v-btn>
  </v-form>
</template>

<script>
import store from "@/store/index";

export default {
  name: "edit-form",

  data: () => ({
    error: "",
    profile: {},
  }),

  methods: {
    submit() {
      const form = new FormData();
      form.append("[profile]nickname", this.profile.nickname);

      fetch(`${process.env.VUE_APP_BACKEND_API_URL}/user/profile`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        method: "PATCH",
        body: form,
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          store.commit("flash", { type: "success", text: "Saved" });
        }
      });
    },

    getProfile() {
      fetch(`${process.env.VUE_APP_BACKEND_API_URL}/user/profile`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.profile = data;
        }
      });
    },
  },

  mounted() {
    this.getProfile();
  },
};
</script>
