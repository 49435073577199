<template>
  <v-form>
    <v-text-field
      v-model="user.email"
      label="Email address"
      :error-messages="error['email']"
    ></v-text-field>

    <v-text-field
      v-model="user.first_name"
      label="First name"
      :error-messages="error['first_name']"
    ></v-text-field>

    <v-text-field
      v-model="user.last_name"
      label="Last name"
      :error-messages="error['last_name']"
    ></v-text-field>

    <v-text-field
      v-model="user.emergency_contact_name"
      label="Emergency contact name"
      :error-messages="error['emergency_contact_name']"
    ></v-text-field>

    <v-text-field
      v-model="user.emergency_contact_number"
      label="Emergency contact number"
      :error-messages="error['emergency_contact_number']"
    ></v-text-field>

    <v-text-field
      v-model="user.password"
      label="Password"
      :error-messages="error['password']"
      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
      :type="show ? 'text' : 'password'"
      @click:append="show = !show"
    ></v-text-field>

    <div class="d-flex justify-space-between">
      <p>
        Already have an account?
        <router-link to="/sign-in">Click here to sign in</router-link>
      </p>

      <v-btn color="primary" @click="submit">Sign up</v-btn>
    </div>
  </v-form>
</template>

<script>
import store from "@/store/index";

export default {
  name: "sign-up-form",

  data: () => ({
    error: "",
    show: false,
    user: {
      email: "",
      first_name: "",
      last_name: "",
      emergency_contact_name: "",
      emergency_contact_number: "",
      password: "",
    },
  }),

  methods: {
    submit() {
      const form = new FormData();
      form.append("[user]email", this.user.email);
      form.append("[user]first_name", this.user.first_name);
      form.append("[user]last_name", this.user.last_name);
      form.append(
        "[user]emergency_contact_name",
        this.user.emergency_contact_name
      );
      form.append(
        "[user]emergency_contact_number",
        this.user.emergency_contact_number
      );

      if (this.user.password) {
        form.append("[user]password", this.user.password);
      }

      fetch(`${process.env.VUE_APP_BACKEND_API_URL}/sign-up`, {
        method: "POST",
        body: form,
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          var token = data["token"];
          store.commit("flash", { type: "success", text: "Welcome!" });
          store.commit("signIn", token);
          this.$router.push({ name: "Home" });
        }
      });
    },
  },
};
</script>
