<template>
  <div>
    <TimerHeader
      v-if="race['registrations'].length <= 0"
      icon="timer-sand"
      title="Waiting for people to sign up"
      subtitle="Nobody has signed up to take part in this event yet"
    />

    <TimerHeader
      v-if="race['registrations'].length > 0"
      icon="clipboard-check-outline"
      title="Checking in paddlers"
      subtitle="Tick everyone who is taking part"
    />

    <v-container>
      <v-row>
        <v-col
          :cols="cols"
          v-for="(user, index) in race['registrations']"
          :key="index"
        >
          <PaddleTimerButton
            v-if="checkedin(user)"
            @click.native="checkout(user)"
            :paddler="user"
            icon="check-circle-outline"
            color="primary"
          />

          <PaddleTimerButton
            v-if="!checkedin(user)"
            @click.native="checkin(user)"
            :paddler="user"
            icon="checkbox-blank-circle-outline"
            color="grey lighten-1"
          />
        </v-col>
      </v-row>
    </v-container>

    <div class="text-center mt-5">
      <v-btn @click="getRegistrations()" color="success"
        >Check for sign ups</v-btn
      >
    </div>
  </div>
</template>

<script>
import store from "@/store/index";
import TimerHeader from "@/components/organiser/paddle-timer/TimerHeader.vue";
import PaddleTimerButton from "@/components/organiser/paddle-timer/PaddleTimerButton.vue";

export default {
  name: "CheckIn",
  props: ["race", "cols"],
  components: {
    TimerHeader,
    PaddleTimerButton,
  },

  data() {
    return {};
  },

  methods: {
    checkin(user) {
      if (!this.checkedin(user)) {
        this.race["paddlers"].push({
          first_name: user.first_name,
          profile_id: user.profile_id,
          tag: user.tag,
          time: 0,
          position: 0,
        });
      }
    },

    checkout(user) {
      var index = this.race["paddlers"].findIndex(
        (item) => item.tag == user.tag
      );
      if (index >= 0) {
        this.race["paddlers"].splice(index, 1);
      }
    },

    checkedin(user) {
      var index = this.race["paddlers"].findIndex(
        (item) => item.tag == user.tag
      );
      return index >= 0;
    },

    getRegistrations() {
      var url = "";
      url += process.env.VUE_APP_BACKEND_API_URL;
      url += "/organiser/races/";
      url += this.race["id"];
      url += "/registrations";

      fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
          store.commit("flash", {
            type: "error",
            text: "Couldn't fetch sign ups",
          });
        }

        if (response.ok) {
          this.race["registrations"] = data;
          data.sort((a, b) => a.tag.localeCompare(b.tag));
          store.commit("flash", { type: "success", text: "Sign ups updated" });
        }
      });
    },
  },
};
</script>
