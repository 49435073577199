<template>
  <v-menu>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        text
        v-bind="attrs"
        v-on="on"
        class="d-none d-md-inline-flex"
      >
        {{ label }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(item, index) in items" :key="index" :to="item.url">
        <v-list-item-title>{{ item.label }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "navbar-dropdown",
  props: ["items", "label"],
};
</script>
