<template>
  <v-container>
    <page-header text="Reset Password" />
    <reset-password-form />
  </v-container>
</template>

<script>
import PageHeader from "@/components/public/PageHeader.vue";
import ResetPasswordForm from "@/components/public/ResetPasswordForm.vue";

export default {
  name: "ResetPassword",

  components: {
    PageHeader,
    ResetPasswordForm,
  },
};
</script>
