<template>
  <v-container>
    <page-header v-bind:text="location.name" />
    <location-details v-bind:location="location" />
  </v-container>
</template>

<script>
import PageHeader from "@/components/public/PageHeader";
import LocationDetails from "@/components/public/location/LocationDetails";

export default {
  name: "location",

  components: {
    PageHeader,
    LocationDetails,
  },

  data: () => ({
    location: {},
  }),

  methods: {
    getData() {
      var url = "";
      url += process.env.VUE_APP_BACKEND_API_URL;
      url += "/locations/";
      url += this.$route.params["id"];

      fetch(url, {
        method: "GET",
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.location = data;
        }
      });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
