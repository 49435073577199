<template>
  <v-container style="max-width: 500px">
    <page-header text="Edit Incident Report" />

    <div v-if="incident_report.race">
      <h2 class="text-h5 text-center">
        {{ incident_report.race.name }}
      </h2>
      <p class="text-center">
        Event id #{{ incident_report.race.id }}
        |
        {{ prettyDate(incident_report.race.scheduled_start_time) }}
      </p>
    </div>

    <v-simple-table>
      <tbody>
        <tr>
          <th>Any incidents to report?</th>
          <td>{{ incident_report.incident ? "Yes" : "No" }}</td>
        </tr>
        <tr>
          <th>Any injurys?</th>
          <td>{{ incident_report.injury ? "Yes" : "No" }}</td>
        </tr>
        <tr>
          <th>Any help required?</th>
          <td>{{ incident_report.assistance }}</td>
        </tr>
        <tr>
          <th>Follow up required?</th>
          <td>{{ incident_report.followup ? "Yes" : "No" }}</td>
        </tr>
        <tr>
          <th>Incident type</th>
          <td>{{ incident_report.category }}</td>
        </tr>
      </tbody>
    </v-simple-table>

    <v-form>
      <v-textarea
        label="Details"
        class="mt-5 mb-5"
        rows="10"
        outlined
        persistent-hint
        v-model="incident_report.details"
        hint="Add any important notes or details about the incident here"
        :error-messages="error['details']"
      ></v-textarea>
    </v-form>

    <div class="d-flex">
      <v-btn color="primary" @click="submit">Save</v-btn>
      <v-btn class="ml-auto" to="/organiser/finished">Cancel</v-btn>
    </div>
  </v-container>
</template>

<script>
import store from "@/store/index";
import PageHeader from "@/components/public/PageHeader.vue";

export default {
  name: "EditIncidientReport",

  components: {
    PageHeader,
  },

  data() {
    return {
      error: "",
      incident_report: {},
    };
  },

  mounted() {
    this.getIncidentReport();
  },

  methods: {
    submit() {
      const form = new FormData();

      var url = "";
      url += process.env.VUE_APP_BACKEND_API_URL;
      url += "/organiser/incident_reports/";
      url += this.$route.params["id"];

      form.append("[incident_report]details", this.incident_report.details);

      fetch(url, {
        method: "PUT",
        body: form,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
          store.commit("flash", {
            type: "error",
            text: "Something went wrong",
          });
        }

        if (response.ok) {
          this.$router.push({ name: "Organiser" });
          store.commit("flash", { type: "success", text: "Saved" });
        }
      });
    },

    getIncidentReport() {
      var url = "";
      url += process.env.VUE_APP_BACKEND_API_URL;
      url += "/organiser/incident_reports/";
      url += this.$route.params["id"];

      fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.incident_report = data;
        }
      });
    },
  },
};
</script>
