<template>
  <v-container>
    <page-header text="Contact AquaPaddle"></page-header>

    <v-form ref="form" v-if="!sent">
      <v-text-field v-model="name" label="Your name" :rules="nameRules">
      </v-text-field>

      <v-text-field v-model="email" label="E-mail address" :rules="emailRules">
      </v-text-field>

      <v-textarea v-model="message" label="Message" :rules="messageRules">
      </v-textarea>

      <v-btn color="accent" @click="send">Send message </v-btn>
    </v-form>

    <v-alert v-if="error" color="red" class="mt-5" dark
      >Message failed to send</v-alert
    >

    <div v-if="sent" class="text-center">
      <v-icon color="green" x-large>mdi-check</v-icon>
      <h2 class="text-h5">Message sent</h2>
    </div>
  </v-container>
</template>

<script>
import PageHeader from "@/components/public/PageHeader.vue";

export default {
  name: "about",

  components: {
    PageHeader,
  },

  data: () => ({
    url: "https://submit-form.com/5gqtn6yB",
    error: false,
    sent: false,
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    message: "",
    messageRules: [
      (v) => !!v || "Message is required",
      (v) => (v && v.length > 25) || "Message must be more than 25 characters",
      (v) =>
        (v && v.length <= 250) || "Message must be less than 250 characters",
    ],
  }),

  methods: {
    send() {
      if (this.$refs.form.validate()) {
        fetch(this.url, {
          method: "POST",
          body: JSON.stringify({
            name: this.name,
            email: this.email,
            message: this.message,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }).then(async (response) => {
          if (!response.ok) {
            this.error = true;
            this.sent = false;
          }

          if (response.ok) {
            this.error = false;
            this.sent = true;
          }
        });
      }
    },
  },
};
</script>
