<template>
  <div>
    <div v-for="race in races" :key="race.id">
      <index-item v-bind:race="race" />
    </div>
  </div>
</template>

<script>
import IndexItem from "@/components/public/races/IndexItem.vue";

export default {
  name: "finished-races",

  data: () => ({
    races: [],
  }),

  components: {
    IndexItem,
  },

  methods: {
    getData() {
      fetch(`${process.env.VUE_APP_BACKEND_API_URL}/races?finished=true`, {
        method: "GET",
      }).then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          this.error = data;
        }

        if (response.ok) {
          this.races = data;
        }
      });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
